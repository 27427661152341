<template>
  <div class="favorites">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/account'"></back-button>
        <div class="title">{{ $t("account.my_favorites") }}</div>
        <h4></h4>
      </div>
    </div>
    <div class="content">
      <restaurant-card
        v-for="(restaurant, index) in restaurants"
        :key="index"
        :restaurant="restaurant"
      ></restaurant-card>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import RestaurantCard from "../../components/customer/RestaurantCard.vue";

export default {
  components: { BackButton, RestaurantCard },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/client/favorites", this.token.config)
        .then((resp) => {
          this.successRequest();
          this.restaurants = resp.data.favorites;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      restaurants: [],
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  gap: 12px;
  height: 88vh;
  overflow-y: scroll;
  padding-top: 70px;
}
</style>