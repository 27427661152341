<template>
  <div class="profile">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/account'"></back-button>
        <div class="title">{{ $t("home.profile") }}</div>
        <h4 @click="edit">
          {{ !editing ? $t("profile.edit") : $t("customer_home.done") }}
        </h4>
      </div>
    </div>
    <div class="content">
      <div class="form">
        <div class="input">
          <label :class="[editing ? 'active' : 'deactive']" for="name">{{
            $t("information.name")
          }}</label>
          <input
            :class="[editing ? 'active' : 'deactive']"
            :disabled="!editing"
            type="text"
            id="name"
            v-model="person.name"
          />
        </div>

        <div class="input">
          <label class="deactive" for="email">{{
            $t("information.email")
          }}</label>
          <input
            class="deactive"
            disabled
            type="text"
            id="email"
            v-model="person.email"
          />
        </div>

        <div class="input">
          <label
            :class="[editing ? 'active' : 'deactive']"
            for="phone_number"
            >{{ $t("information.phone_number") }}</label
          >
          <input
            :class="[editing ? 'active' : 'deactive']"
            :disabled="!editing"
            type="text"
            id="phone_number"
            v-model="person.phone_number"
          />
        </div>
        <router-link to="/change-password">
          <div class="btn">
            <div class="left">
              <div class="icon password"></div>
              <h4 class="caption">{{ $t("profile.change_password") }}</h4>
            </div>
            <div class="icon arrow"></div>
          </div>
        </router-link>
      </div>
      <button @click="showModal('logout_modal')" class="outline logout">
        {{ $t("settings.logout") }}
      </button>
    </div>
    <div id="blur_background" class="blur-bg">
      <logout-modal></logout-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import LogoutModal from "../../components/common/LogoutModal.vue";

export default {
  components: { BackButton, LogoutModal },
  mounted() {
    this.fetch();
    this.iOSBottomGapFixer(".content");
  },
  methods: {
    edit() {
      this.editing = !this.editing;
      if (!this.editing) {
        let data = {
          name: this.person.name,
          email: this.person.email,
          phone_number: this.person.phone_number,
        };
        this.global_loading.show = true;
        this.$http
          .patch(this.$hostname + "/client/profile", data, this.token.config)
          .then((resp) => {
            this.successRequest();
            this.person.name = resp.data.name;
            this.person.email = resp.data.email;
            this.person.phone_number = resp.data.phone_number;
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/client/profile", this.token.config)
        .then((resp) => {
          this.successRequest();
          this.person.name = resp.data.information[0].name;
          this.person.email = resp.data.information[0].email;
          this.person.phone_number = resp.data.information[0].phone_number;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      editing: false,
      person: {
        name: "",
        email: "",
        phone_number: "",
      },
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 100vh;
  padding: 70px 16px;
  justify-content: space-between;
}
.title {
  width: 80%;
}
.header h4 {
  width: fit-content;
  cursor: pointer;
}
.form {
  padding-bottom: 16px;
  border-bottom: 1px solid #f0edf2;
}
.password {
  background-image: url("../../assets/common/key.svg");
}

input {
  width: 55%;
}
</style>