<template>
  <div class="modal" id="rate_modal">
    <div class="content">
      <div class="head">
        <button @click="closeModal('rate_modal')" class="icon close"></button>
        <div class="title">{{ $t("rate.rate") }}</div>
        <span></span>
      </div>
      <div class="body">
        <div class="title">{{ $t("rate.we_like_what_you_think") }}</div>
        <div class="icon banner"></div>
        <h4>
          <div class="icon tip"></div>
          {{ $t("rate.what_you_think") }}
        </h4>
        <rating></rating>
        <h4>
          <div class="icon tip"></div>
          {{ $t("rate.how_you_felt") }}
        </h4>
        <textarea
          name="comment"
          :placeholder="$t('order.type_here')"
          id="comment"
          cols="30"
          rows="10"
          v-model="comment"
        ></textarea>
      </div>
      <button @click="submit" class="fill">
        {{ $t("feedback.submit") }}
      </button>
    </div>
  </div>
</template>
<script>
import Rating from "../common/Rating.vue";
export default {
  components: { Rating },
  methods: {
    submit() {
      let data = {
        vote: parseInt(localStorage.vote),
        order_id: this.order_processing.data.id,
        qr: this.order_processing.data.restaurant.menu.qr_url,
        comment: this.comment,
      };
      this.global_loading.show = true;
      this.$http
        .post(this.$hostname + "/client/rate", data, this.token.config)
        .then((resp) => {
          this.successRequest();
          this.global_success.message =
            this.$t("rate.submited") + "\n" + this.$t("rate.thanks");
            
          this.closeModal("rate_modal");

          setTimeout(() => {
            this.showModal("succeed_modal");
          }, 301);

          setTimeout(() => {
            this.$router.push("/history");
          }, 3000);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      comment: "",
    };
  },
};
</script>
<style scoped>
.modal {
  padding: 0;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #4d4d4d;
  width: 100%;
  border-bottom: 1px solid #f0edf2;
  padding: 12px 0;
}

.content {
  background-color: #ffffff;
  height: 100vh;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  height: 70vh;
  overflow-y: scroll;
}
.title {
  font-size: 22px;
  color: #412250;
}
.close {
  background-image: url("../../assets/common/close.svg");
  height: 28px;
  width: 28px;
}

.banner {
  min-height: 160px;
  min-width: 160px;
  background-size: 160px;
  background-image: url("../../assets/customer/restaurant/rating.png");
}
h4 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}
textarea {
  background-color: #f5f6f7;
  border-radius: 8px;
  padding: 12px;
  outline: none;
  resize: none;
  min-height: 160px;
  width: 100%;
}
textarea::placeholder {
  color: #b3b3b3;
}
.fill {
  width: 100%;
}
</style>