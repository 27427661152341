<template></template><template>
  <div class="login">
    <div class="header">
      <div class="container">
        <translate-btn></translate-btn>
        <div class="light-banner"></div>
        <router-link to="/">Skip</router-link>
      </div>
    </div>
    <div class="content">
      <h1>
        {{ $t("registration.create") }}
        <br />
        {{ $t("registration.account") }}
      </h1>
      <div class="input">
        <label class="active" for="name">{{ $t("information.name") }}</label>
        <input
          v-model="name"
          class="active"
          type="text"
          id="name"
          v-on:keyup.enter="register"
          @change="nameValidation"
        />
      </div>
      <div v-if="name_invalid" class="validation-error">
        *{{ $t("registration.name_error") }}!
      </div>

      <div class="input">
        <label class="active" for="email">{{ $t("information.email") }}</label>
        <input
          v-model="email"
          class="active"
          type="email"
          id="email"
          v-on:keyup.enter="register"
          @change="emailValidation"
        />
      </div>
      <div v-if="email_invalid" class="validation-error">
        *{{ $t("registration.email_error") }}!
      </div>
      <div v-if="msg_email_invalid.length" class="validation-error">
        *{{ msg_email_invalid }}!
      </div>

      <div class="input phone">
        <label class="active" for="phone_number">{{
          $t("information.phone_number")
        }}</label>
        <input
          v-model="phone_number"
          class="active"
          type="text"
          id="phone_number"
          placeholder="0413147604"
          v-on:keyup.enter="register"
          @change="phoneNumberValidation"
        />
      </div>
      <div v-if="phone_number_invalid" class="validation-error">
        *{{ $t("registration.phone_number_error") }}!
      </div>

      <div class="input">
        <label class="active" for="password">{{
          $t("registration.password")
        }}</label>
        <input
          v-model="password"
          class="active"
          type="password"
          name="password"
          id="password"
          v-on:keyup.enter="register"
          @change="passwordValidation"
        />
        <div
          @click="showPassword('password')"
          :class="[eye ? 'show_password' : 'hide_password', 'icon']"
        ></div>
      </div>
      <div v-if="password_invalid" class="validation-error">
        *{{ $t("registration.password_error") }}.
      </div>
      <div v-if="password_weak.length" class="validation-error">
        *{{ password_weak }}.
      </div>

      <div class="policy">
        <!-- <input
          v-model="policy_check"
          type="checkbox"
          name="policy_check"
          class="policy_check"
          id="policy_check"
        /> -->
        {{ $t("registration.agree_privacy_policy") }}
        <u @click="showModal('privacy_modal')">{{
          $t("registration.terms")
        }}</u>
      </div>

      <button @click="register()" class="fill">
        {{ $t("registration.create_account") }}
      </button>

      <button @click="google()" class="outline">
        <div class="icon google"></div>
        {{ $t("registration.google") }}
      </button>

      <router-link to="/login">
        {{ $t("registration.already_member") }}
        <u> {{ $t("registration.sign_in") }} </u>
      </router-link>
    </div>
    <div id="blur_background" class="blur-bg">
      <privacy-modal></privacy-modal>
      <otp-verification-modal :user="user"></otp-verification-modal>
    </div>
  </div>
</template>
<script>
import PrivacyModal from "../../components/common/PrivacyModal.vue";
import TranslateBtn from "../../components/common/TranslateBtn.vue";
import OtpVerificationModal from "../../components/customer/OtpVerificationModal.vue";

export default {
  components: { PrivacyModal, TranslateBtn, OtpVerificationModal },
  mounted() {
    this.iOSBottomGapFixer(".login");
  },
  methods: {
    showPassword(password_id) {
      this.eye = this.toggleShowPassword(password_id);
    },
    nameValidation() {
      if (this.name) {
        this.name_invalid = false;
      } else {
        this.name_invalid = true;
      }
    },
    emailValidation() {
      this.validateEmailFormat();
      if (this.email) {
        this.email_invalid = false;
      } else {
        this.email_invalid = true;
      }
    },
    validateEmailFormat() {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.msg_email_invalid = "Please enter a valid email address";
      } else {
        this.msg_email_invalid = "";
      }
    },
    phoneNumberValidation() {
      if (this.validPhoneNumber) {
        this.phone_number_invalid = false;
      } else {
        this.phone_number_invalid = true;
      }
    },
    passwordValidation() {
      // this.checkWeaknessPassword();
      if (this.validPassword) {
        this.password_invalid = false;
      } else {
        this.password_invalid = true;
      }
    },
    checkWeaknessPassword() {
      if (
        !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
          this.password
        )
      ) {
        this.password_weak =
          "The password must include min 8 letter password, with at least a symbol, upper and lower case letters and a number";
      } else {
        this.password_weak = "";
      }
    },
    register() {
      this.nameValidation();
      this.emailValidation();
      this.phoneNumberValidation();
      this.passwordValidation();
      if (
        !this.name_invalid &&
        !this.email_invalid &&
        !this.password_invalid &&
        !this.phone_number_invalid
      ) {
        let data = {
          phone_number: this.phone_number,
          email: this.email,
          password: this.password,
          lang: localStorage.language,
        };

        this.user_signup.name = this.name;
        this.user_signup.email = this.email;
        this.user_signup.phone_number = this.phone_number;
        this.user_signup.password = this.password;

        this.global_loading.show = true;
        this.$http
          .post(this.$hostname + "/send-otp", data)
          .then((resp) => {
            this.user_signup.expired_at = resp.data.expired_at;
            this.successRequest();
            this.showModal("otp_verification_modal");
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
  },
  computed: {
    validPhoneNumber() {
      return (
        (this.phone_number.charAt(0) == 0 ||
          this.phone_number.substring(0, 4) == "+358") &&
        this.phone_number.length >= 7 &&
        this.phone_number.length <= 17
      );
    },
    validPassword() {
      return this.password.length >= 8;
    },
  },
  data() {
    return {
      eye: true,
      new_eye: true,
      name: "",
      email: "",
      phone_number: "",
      password: "",
      user: {
        name: "",
        email: "",
        phone_number: "",
        password: "",
      },
      msg_email_invalid: "",
      password_weak: "",
      name_invalid: false,
      email_invalid: false,
      phone_number_invalid: false,
      password_invalid: false,
    };
  },
};
</script>
<style scoped>
.login {
  max-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-image: url("../../assets/common/background.jpg"),
    linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 99.99%);
  background-blend-mode: saturation;
  background-size: cover;
  /* -webkit-padding-after: 80px; */
}
.header {
  background: transparent;
  position: unset;
}

.container {
  border: none;
  justify-content: space-between;
}

.content {
  margin: 0 auto;
  gap: 12px;
  padding: 0 16px 24px;
  height: auto;
}
h1 {
  font-weight: 500;
  font-size: 32px;
  color: #f5f6f7;
}
.input {
  position: relative;
  background: rgba(245, 246, 247, 0.1);
  backdrop-filter: blur(50px);
}
.input label {
  color: #b3b3b3;
}

.input input {
  color: #f5f6f7;
  background: transparent;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #f5f6f7 !important;
  -webkit-background-clip: text;
}

.outline {
  position: relative;
}
.google {
  left: 4px;
  bottom: 4px;
  width: 40px;
  height: 40px;
  background-size: 40px;
  position: absolute;
  background-image: url("../../assets/common/google.svg");
}

.phone label {
  width: 19% !important;
}

.phone input {
  width: 60% !important;
}

.policy {
  font-size: 14px;
  text-align: left;
  color: #f5f6f7;
  gap: 6px;
}

.policy u {
  cursor: pointer;
}

input[type="checkbox"] {
  transition: 0.5s ease;
  transform: rotate(180deg);
  background-image: url("../../assets/customer/account/checkbox.svg");
}
input[type="checkbox"]:checked {
  transform: rotate(0deg);
  transition: 0.5s ease;
  background-image: url("../../assets/customer/account/checked.svg");
}
a {
  font-size: 14px;
  text-align: center;
  color: #f5f6f7;
}

.validation-error {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ff6a4d;
}
</style>