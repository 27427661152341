<template>
  <div class="options">
    <!-- wifi -->
    <div v-if="wifi == 1" class="option">
      <div class="icon wifi"></div>
      <div class="caption">Free WI-FI</div>
    </div>

    <div v-if="wifi == 1 && bar == 1" class="line"></div>

    <!-- bar -->
    <div v-if="bar == 1" class="option">
      <div class="bar icon"></div>
      <div class="caption">Bar</div>
    </div>

    <div
      v-if="(parking == 1 && wifi == 1) || (parking == 1 && bar == 1)"
      class="line"
    ></div>

    <!-- parking -->
    <div v-if="parking == 1" class="option">
      <div class="parking icon"></div>
      <div class="caption">Car Park</div>
    </div>

    <div
      v-if="
        (wheelchair == 1 && wifi == 1) ||
        (wheelchair == 1 && bar == 1) ||
        (wheelchair == 1 && parking == 1)
      "
      class="line"
    ></div>

    <!-- wheelchair -->
    <div v-if="wheelchair == 1" class="option">
      <div class="icon wheelchair"></div>
      <div class="caption">Wheelchair Accessible</div>
    </div>

    <div
      v-if="
        (outdoor == 1 && wifi == 1) ||
        (outdoor == 1 && bar == 1) ||
        (outdoor == 1 && parking == 1) ||
        (outdoor == 1 && wheelchair == 1)
      "
      class="line"
    ></div>

    <!-- outdoor -->
    <div v-if="outdoor == 1" class="option">
      <div class="outdoor icon"></div>
      <div class="caption">Outdoor Dining</div>
    </div>

    <div
      v-if="
        (smoking == 1 && wifi == 1) ||
        (smoking == 1 && bar == 1) ||
        (smoking == 1 && parking == 1) ||
        (smoking == 1 && wheelchair == 1) ||
        (smoking == 1 && outdoor == 1)
      "
      class="line"
    ></div>

    <!-- smoking -->
    <div v-if="smoking == 1" class="option">
      <div class="icon smoking"></div>
      <div class="caption">No Smoking</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    wifi: 0,
    bar: 0,
    smoking: 0,
    parking: 0,
    wheelchair: 0,
    outdoor: 0,
  },
};
</script>
<style scoped>
.options {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-around;
}

.option {
  width: 33%;
  font-weight: 300;
  font-size: 0.8em;
  color: #737373;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.right_line {
  border-right: 1px solid #f0edf2;
}
.icon {
  width: 24px;
  height: 28px;
}

.wifi {
  background-image: url("../../assets/restaurant/home/wifi_on.svg");
}
.bar {
  background-image: url("../../assets/restaurant/home/bar_on.svg");
}

.smoking {
  background-image: url("../../assets/restaurant/home/smoking_on.svg");
}

.parking {
  background-image: url("../../assets/restaurant/home/parking_on.svg");
}

.wheelchair {
  background-image: url("../../assets/restaurant/home/wheelchair_on.svg");
}

.outdoor {
  background-image: url("../../assets/restaurant/home/outdoor_on.svg");
}
</style>