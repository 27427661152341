<template>
  <div class="login">
    <div class="header">
      <div class="container">
        <translate-btn></translate-btn>
        <div class="light-banner"></div>
        <router-link to="/">Skip</router-link>
      </div>
    </div>
    <div class="content">
      <h1>{{ $t("registration.sign_in") }}</h1>
      <div class="input">
        <label class="active" for="email">{{ $t("information.email") }}</label>
        <input
          v-on:keyup.enter="login()"
          v-model="email"
          class="active"
          type="email"
          id="email"
        />
      </div>
      <div class="input">
        <label class="active" for="password">{{
          $t("registration.password")
        }}</label>
        <input
          v-model="password"
          class="active"
          type="password"
          name="password"
          id="password"
          v-on:keyup.enter="login()"
        />
        <div
          @click="showPassword('password')"
          :class="[eye ? 'show_password' : 'hide_password', 'icon']"
        ></div>
      </div>
      <div @click="showModal('forgot_password_modal')" class="forgot">
        {{ $t("registration.forgot_password") }}
      </div>
      <button @click="login()" class="fill">
        {{ $t("registration.sign_in") }}
      </button>

      <button @click="google()" class="outline">
        <div class="icon google"></div>
        {{ $t("registration.google") }}
      </button>

      <router-link to="/sign-up">
        {{ $t("registration.dont_have_account") }}
        <u> {{ $t("registration.create_account") }} </u>
      </router-link>
    </div>

    <div id="blur_background" class="blur-bg">
      <forgot-password-modal></forgot-password-modal>
      <reset-password-modal></reset-password-modal>
      <succeed-modal :message="global_success.message"></succeed-modal>
    </div>
  </div>
</template>
<script>
import ForgotPasswordModal from "../../components/customer/ForgotPasswordModal.vue";
import ResetPasswordModal from "../../components/customer/ResetPasswordModal.vue";
import SucceedModal from "../../components/customer/SucceedModal.vue";
import TranslateBtn from "../../components/common/TranslateBtn.vue";

export default {
  components: {
    ForgotPasswordModal,
    ResetPasswordModal,
    TranslateBtn,
    SucceedModal,
  },
  mounted() {
    this.iOSBottomGapFixer(".login");

    if (this.$route.query.token && this.$route.query.email) {
      this.showModal("reset_password_modal");
    }
    if (this.$route.query.token && this.$route.query.name) {
      if (this.isItiOS()) {
        window.location =
          "com.nordantia.tilaa://nordantia/home?token=" +
          this.$route.query.token;
      }
      
      localStorage.token = this.$route.query.token;
      this.token.config.headers.Authorization = "Bearer " + localStorage.token;

      if (localStorage.redirect_to) {
        this.$router.push(localStorage.redirect_to);
      } else {
        this.gotoHome();
      }
    }
  },
  methods: {
    async login() {
      let data = {
        email: this.email,
        password: this.password,
      };
      this.global_loading.show = true;
      await this.$http
        .post(this.$hostname + "/login", data)
        .then((resp) => {
          this.successRequest();
          this.$store.dispatch("login", {
            auth: true,
          });

          localStorage.auth = true;

          localStorage.token = resp.data.token;

          this.token.config.headers.Authorization =
            "Bearer " + localStorage.token;
          if (localStorage.redirect_to) {
            this.$router.push(localStorage.redirect_to);
          } else {
            this.gotoHome();
          }
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    showPassword(password_id) {
      this.eye = this.toggleShowPassword(password_id);
    },
  },
  data() {
    return {
      eye: true,
      email: "",
      password: "",
    };
  },
};
</script>
<style scoped>
.login {
  max-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-image: url("../../assets/common/background.jpg"),
    linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 99.99%);
  background-blend-mode: saturation;
  background-size: cover;
  /* -webkit-padding-after: 80px; */
}
.header {
  background: transparent;
  position: unset;
}

.container {
  border: none;
  justify-content: space-between;
}

.content {
  margin: 0 auto;
  gap: 12px;
  padding: 0 16px 24px;
  height: auto;
}
h1 {
  font-weight: 500;
  font-size: 32px;
  color: #f5f6f7;
}

.input {
  position: relative;
  background: rgba(245, 246, 247, 0.1);
  backdrop-filter: blur(50px);
}

.input label {
  color: #b3b3b3;
}

.input input {
  color: #f5f6f7;
  background: transparent;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #f5f6f7 !important;
  -webkit-background-clip: text;
}

.forgot {
  font-size: 14px;
  width: 100%;
  text-align: right;
  text-decoration-line: underline;
  color: #f5f6f7;
  cursor: pointer;
}
.outline {
  position: relative;
}
.google {
  left: 4px;
  bottom: 4px;
  width: 40px;
  height: 40px;
  background-size: 40px;
  position: absolute;
  background-image: url("../../assets/common/google.svg");
}
a {
  font-size: 14px;
  text-align: center;
  color: #f5f6f7;
}
</style>