<template>
  <div @click.prevent="toggleSwitcher" class="switcher">
    <div :class="[selected ? 'selected' : '', 'option']" id="close">
      {{ first_option }}
    </div>
    <div class="selector" id="switch_selector"></div>
    <div :class="[!selected ? 'selected' : '', 'option']" id="open">
      {{ second_option }}
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toggleSwitcher() {
      this.selected = this.toggleSwitch(this.selected);
      this.$emit("selected_option", this.selected);
    },
  },
  props: {
    first_option: "",
    second_option: "",
  },
  data() {
    return {
      selected: true,
    };
  },
};
</script>
<style scoped>
.switcher {
  position: relative;
  display: flex;
  width: 100%;
  height: 40px;
  margin: 0px auto;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  background-color: #f5f6f7;
  border-radius: 8px;
  color: #b3b3b3;
  font-size: 14px;
  max-width: 768px;
  /* z-index: -3; */
}
.switcher .option {
  padding: 8px 16px;
  width: 49%;
  text-align: center;
  cursor: pointer;
  z-index: 5;
}
.switcher .selected {
  color: #4d4d4d;
  font-weight: 500;
}
.switcher .selector {
  position: absolute;
  left: 4px;
  height: 32px;
  width: 49%;
  z-index: 4;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  transition: 0.3s ease;
}
</style>