<template>
  <div id="signup_modal" class="modal">
    <div class="content">
      <div class="head">
        <button @click="closeModal('signup_modal')" class="icon close"></button>
        <div>{{ $t("registration.create_account") }}</div>
        <span></span>
      </div>

      <div class="note">
        {{ $t("registration.create_to_continue_order") }}
      </div>

      <div class="input">
        <label class="active" for="name">{{ $t("information.name") }}</label>
        <input
          v-model="name"
          class="active"
          type="text"
          id="name"
          v-on:keyup.enter="register"
          @change="nameValidation"
        />
      </div>
      <div v-if="name_invalid" class="validation-error">
        *{{ $t("registration.name_error") }}!
      </div>

      <div class="input">
        <label class="active" for="create_email">{{
          $t("information.email")
        }}</label>
        <input
          v-model="email"
          class="active"
          type="email"
          id="create_email"
          v-on:keyup.enter="register"
          @change="emailValidation"
        />
      </div>
      <div v-if="email_invalid" class="validation-error">
        *{{ $t("registration.email_error") }}!
      </div>
      <div v-if="msg_email_invalid.length" class="validation-error">
        *{{ msg_email_invalid }}!
      </div>

      <div class="input phone">
        <label class="active" for="create_phone_number">{{
          $t("information.phone_number")
        }}</label>
        <input
          v-model="phone_number"
          class="active"
          type="text"
          id="create_phone_number"
          v-on:keyup.enter="register"
          @change="phoneNumberValidation"
        />
      </div>
      <div v-if="phone_number_invalid" class="validation-error">
        *{{ $t("registration.phone_number_error") }}!
      </div>

      <div class="input">
        <label class="active" for="create_password">{{
          $t("registration.password")
        }}</label>
        <input
          v-model="password"
          class="active"
          type="password"
          name="password"
          id="create_password"
          v-on:keyup.enter="register"
          @change="passwordValidation"
        />
        <div
          @click="showPassword('create_password')"
          :class="[eye ? 'show_password' : 'hide_password', 'icon']"
        ></div>
      </div>
      <div v-if="password_invalid" class="validation-error">
        *{{ $t("registration.password_error") }}.
      </div>
      <div v-if="password_weak.length" class="validation-error">
        *{{ password_weak }}.
      </div>

      <div class="policy">
        <!-- <input
        v-model="policy_check"
        type="checkbox"
        name="policy_check"
        class="policy_check"
        id="policy_check"
      /> -->
        {{ $t("registration.agree_privacy_policy") }}
        <u @click="showTermsAndPolicy()">{{ $t("registration.terms") }}</u>
      </div>

      <button @click="register()" class="fill">
        {{ $t("registration.create_account") }}
      </button>

      <button @click="google()" class="outline">
        <div class="icon google"></div>
        {{ $t("registration.google") }}
      </button>

      <div @click="switchToLoginModal" class="switch">
        {{ $t("registration.already_member") }}
        <u> {{ $t("registration.sign_in") }} </u>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    switchToLoginModal() {
      let signup_modal = document.getElementById("signup_modal");

      setTimeout(() => {
        signup_modal.style.bottom = "-100%";
      }, 100);

      setTimeout(() => {
        signup_modal.style.display = "none";
      }, 300);

      let login_modal = document.getElementById("login_modal");

      const blur_background = document.getElementById("blur_background");
      blur_background.style.display = "none";

      login_modal.style.display = "flex";

      blur_background.style.display = "block";

      blur_background.addEventListener(
        "click",
        (event) => {
          if (event.target.id == "blur_background") {
            this.$router.replace({ query: null }).catch(() => {});

            setTimeout(() => {
              login_modal.style.bottom = "-100%";
            }, 100);
            setTimeout(() => {
              login_modal.style.display = "none";

              blur_background.style.display = "none";
            }, 300);
            return;
          }
        },
        false
      );

      setTimeout(() => {
        login_modal.style.bottom = "0%";
      }, 100);
    },
    showPassword(password_id) {
      this.eye = this.toggleShowPassword(password_id);
    },
    nameValidation() {
      if (this.name) {
        this.name_invalid = false;
      } else {
        this.name_invalid = true;
      }
    },
    emailValidation() {
      this.validateEmailFormat();

      if (this.email) {
        this.email_invalid = false;
      } else {
        this.email_invalid = true;
      }
    },
    validateEmailFormat() {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.msg_email_invalid = "Please enter a valid email address";
      } else {
        this.msg_email_invalid = "";
      }
    },
    phoneNumberValidation() {
      if (this.validPhoneNumber) {
        this.phone_number_invalid = false;
      } else {
        this.phone_number_invalid = true;
      }
    },
    passwordValidation() {
      // this.checkWeaknessPassword();
      if (this.validPassword) {
        this.password_invalid = false;
      } else {
        this.password_invalid = true;
      }
    },
    checkWeaknessPassword() {
      if (
        !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
          this.password
        )
      ) {
        this.password_weak =
          "The password must include min 8 letter password, with at least a symbol, upper and lower case letters and a number";
      } else {
        this.password_weak = "";
      }
    },
    register() {
      this.nameValidation();
      this.emailValidation();
      this.phoneNumberValidation();
      this.passwordValidation();
      if (
        !this.name_invalid &&
        !this.email_invalid &&
        !this.password_invalid &&
        !this.phone_number_invalid
      ) {
        let data = {
          phone_number: this.phone_number,
          email: this.email,
          password: this.password,
          lang: localStorage.language,
        };

        this.user_signup.name = this.name;
        this.user_signup.email = this.email;
        this.user_signup.phone_number = this.phone_number;
        this.user_signup.password = this.password;

        this.global_loading.show = true;
        this.$http
          .post(this.$hostname + "/send-otp", data)
          .then((resp) => {
            this.user_signup.expired_at = resp.data.expired_at;
            this.successRequest();
            // this.showModal("otp_verification_modal");
            this.showOtpModal();
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
    showOtpModal() {
      let signup_modal = document.getElementById("signup_modal");

      setTimeout(() => {
        signup_modal.style.bottom = "-100%";
      }, 100);

      setTimeout(() => {
        signup_modal.style.display = "none";
      }, 300);

      let otp_verification_modal = document.getElementById(
        "otp_verification_modal"
      );

      setTimeout(() => {
        otp_verification_modal.style.bottom = "0%";
      }, 100);

      setTimeout(() => {
        otp_verification_modal.style.display = "flex";
      }, 300);

      // this.showModal("otp_verification_modal");
    },
    showTermsAndPolicy() {
      let signup_modal = document.getElementById("signup_modal");

      setTimeout(() => {
        signup_modal.style.bottom = "-100%";
      }, 100);

      setTimeout(() => {
        signup_modal.style.display = "none";
      }, 300);

      let privacy_modal = document.getElementById("privacy_modal");

      const blur_background = document.getElementById("blur_background");
      blur_background.style.display = "none";

      privacy_modal.style.display = "flex";

      blur_background.style.display = "block";

      blur_background.addEventListener(
        "click",
        (event) => {
          if (event.target.id == "blur_background") {
            this.$router.replace({ query: null }).catch(() => {});

            setTimeout(() => {
              privacy_modal.style.bottom = "-100%";
            }, 100);
            setTimeout(() => {
              privacy_modal.style.display = "none";

              blur_background.style.display = "none";
            }, 300);
            return;
          }
        },
        false
      );

      setTimeout(() => {
        privacy_modal.style.bottom = "0%";
      }, 100);
    },
  },
  computed: {
    validPhoneNumber() {
      return (
        /^\d*$/.test(
          this.phone_number.substring(1, this.phone_number.length)
        ) &&
        (this.phone_number.charAt(0) == 0 ||
          this.phone_number.substring(0, 4) == "+358") &&
        this.phone_number.length >= 7 &&
        this.phone_number.length <= 17
      );
    },
    validPassword() {
      return this.password.length >= 8;
    },
  },
  data() {
    return {
      eye: true,
      new_eye: true,
      name: "",
      email: "",
      phone_number: "",
      password: "",
      user: {
        name: "",
        email: "",
        phone_number: "",
        password: "",
      },
      msg_email_invalid: "",
      password_weak: "",
      name_invalid: false,
      email_invalid: false,
      phone_number_invalid: false,
      password_invalid: false,
    };
  },
};
</script>
<style scoped>
.modal {
  min-height: 90vh;
  background-color: #ffffff;
  height: fit-content;
  max-height: 90vh;
  gap: 12px;
  justify-content: flex-start;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  max-width: 768px;
  margin: 0 auto;
  padding: 0 12px 88px;
}

.content {
  background-color: #ffffff;
  height: 100%;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  margin: 0;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #4d4d4d;
  width: 100%;
  padding: 12px 0 0;
}

.close {
  background-image: url("../../assets/common/close.svg");
  height: 28px;
  width: 28px;
}

button {
  width: 100%;
}

.outline {
  position: relative;
}

.google {
  left: 4px;
  bottom: 4px;
  width: 40px;
  height: 40px;
  background-size: 40px;
  position: absolute;
  background-image: url("../../assets/common/google.svg");
}
.note {
  font-size: 14px;
  text-align: left;
  color: #b3b3b3;
  display: flex;
  align-items: center;
  gap: 6px;
}
.input label {
  color: #b3b3b3;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #4d4d4d !important;
  -webkit-background-clip: text;
}

.show_password {
  background-image: url("../../assets/common/show_dark.svg");
}
.hide_password {
  background-image: url("../../assets/common/hide_dark.svg");
}

.switch {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 100%;
  color: #ff6a4d;
}

.policy {
  font-size: 14px;
  text-align: left;
  color: #b3b3b3;
  gap: 6px;
}

.policy u {
  cursor: pointer;
}

.validation-error {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ff6a4d;
  width: 100%;
}
</style>