<template>
  <div :class="[$route.name == 'Orders' ? 'restaurant' : '', 'counter']">
    <div class="hour">
      {{ displayHour ? displayHour : "--" }}
    </div>
    :
    <div class="minute">
      {{ displayMin ? displayMin : "--" }}
    </div>
    :
    <div class="seconds">
      {{ displaySec ? displaySec : "--" }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["end"],
  data: () => ({
    displayHour: 0,
    displayMin: 0,
    displaySec: 0,
  }),
  mounted() {
    this.showRemaining();
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60;
    },
    _hours() {
      return this._minutes * 60;
    },
    _days() {
      return this._hours * 24;
    },
  },
  methods: {
    timeOver(val) {
      this.$emit("time_over", val);
    },
    formatNum: (num) => (num < 10 ? "0" + num : num),
    showRemaining() {
      const timer = setInterval(() => {
        const now = new Date();

        const end = new Date(Date.parse(this.end));

        const distance = end.getTime() - now.getTime();

        this.timeOver(false);

        if (distance < 0) {
          this.timeOver(true);
          clearInterval(timer);
          return;
        }

        const hours = Math.floor((distance % this._days) / this._hours);
        const minutes = Math.floor((distance % this._hours) / this._minutes);
        const seconds = Math.floor((distance % this._minutes) / this._seconds);

        this.displayHour = this.formatNum(hours);

        this.displayMin = this.formatNum(minutes);

        this.displaySec = this.formatNum(seconds);
      }, 1000);
    },
  },
};
</script>

<style scoped>
.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #776282;
}
.restaurant {
  color: #5a187a;
}
</style>