<template>
  <div id="install_guide_android_modal" class="modal">
    <div class="question">
      <div class="phone"></div>
      <div class="description">
        {{ $t("customer_home.for_better_experience") }}
      </div>
    </div>
    <button @click="done" class="fill">
      {{ $t("customer_home.add_home_screen") }}
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      deferredPrompt: null,
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async done() {
      this.deferredPrompt.prompt();

      this.closeModal("install_guide_modal");
    },
  },
};
</script>
<style scoped>
.modal {
  gap: 12px;
  justify-content: flex-start;
}
.question {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 16px;
  height: 212px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.phone {
  width: 80px;
  height: 116px;
  background-image: url("../../assets/customer/restaurant/install_android.svg");
}
.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  width: 100%;
  color: #737373;
}
.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.steps .description {
  font-size: 12px;
}

.share,
.add_home {
  width: 40%;
}

.next {
  width: 24px;
  background-image: url("../../assets/customer/restaurant/next.svg");
}

.add_home .icon {
  height: 48px;
  background-size: 36px;
  background-image: url("../../assets/customer/restaurant/add_home.svg");
}

.share .icon {
  height: 44px;
  background-image: url("../../assets/customer/restaurant/share.svg");
}
</style>