<template>
  <div id="clear_basket_modal" class="modal">
    <div class="question">
      <h3>{{ $t("cart.sure_clear") }}</h3>
      <h5>
        {{ $t("cart.sure_clear_desc") }}
      </h5>
      <button @click="clearBasket()" class="cancel">
        {{ $t("cart.yes") }}
      </button>
    </div>
    <button @click="closeModal('clear_basket_modal')" class="accept">
      {{ $t("cart.no") }}
    </button>
  </div>
</template>
<script>
export default {
  methods: {
    clearBasket() {
      localStorage.removeItem("total");
      localStorage.removeItem("comment");
      localStorage.removeItem("table");
      localStorage.removeItem("cart");
      localStorage.removeItem("address_id");
      localStorage.removeItem("payment_type");
      localStorage.removeItem("order_type");
      localStorage.removeItem("menu");
      this.$store.dispatch("clearCart");

      this.closeModal("clear_basket_modal");

      setTimeout(() => {
        localStorage.menu = localStorage.qr;
        
        localStorage.restaurant_name = this.$store.state.restaurant;

        this.openItemModal();
      }, 500);
    },
  },
};
</script>
<style scoped>
.modal {
  height: 315px;
  gap: 12px;
  justify-content: flex-start;
}
.question {
  background-color: #ffffff;
  border-radius: 16px;
}

h3 {
  height: 53px;
  padding: 16px 0 16px;
}

h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #b3b3b3;
  padding: 12px 16px;
  border-bottom: 1px solid #f5f6f7;
}

button {
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  height: 48px;
}
button.cancel {
  border-radius: 16px;
  font-weight: 500;
  font-size: 18px;
}
button.accept {
  border-radius: 16px;
  color: #ff6a4d;
  font-size: 18px;
}
</style>