<template>
  <div id="login_modal" class="modal">
    <div class="content">
      <div class="head">
        <button @click="closeModal('login_modal')" class="icon close"></button>
        <div>{{ $t("registration.sign_in") }}</div>
        <span></span>
      </div>

      <div class="note">
        {{ $t("registration.login_to_continue_order") }}
      </div>

      <div class="input">
        <label class="active" for="email">{{ $t("information.email") }}</label>
        <input
          v-on:keyup.enter="login()"
          v-model="email"
          class="active"
          type="email"
          id="email"
        />
      </div>
      <div class="input">
        <label class="active" for="password">{{
          $t("registration.password")
        }}</label>
        <input
          v-model="password"
          class="active"
          type="password"
          name="password"
          id="password"
          v-on:keyup.enter="login()"
        />
        <div
          @click="showPassword('password')"
          :class="[eye ? 'show_password' : 'hide_password', 'icon']"
        ></div>
      </div>

      <div @click="resetPassword()" class="forgot">
        {{ $t("registration.forgot_password") }}
      </div>

      <button @click="login()" class="fill">
        {{ $t("registration.sign_in") }}
      </button>

      <button @click="google()" class="outline">
        <div class="icon google"></div>
        {{ $t("registration.google") }}
      </button>

      <div @click="switchToSignupModal" class="switch">
        {{ $t("registration.dont_have_account") }}
        <u> {{ $t("registration.create_account") }} </u>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    switchToSignupModal() {
      let login_modal = document.getElementById("login_modal");

      setTimeout(() => {
        login_modal.style.bottom = "-100%";
      }, 100);

      setTimeout(() => {
        login_modal.style.display = "none";
      }, 300);

      let signup_modal = document.getElementById("signup_modal");

      signup_modal.style.display = "flex";

      setTimeout(() => {
        signup_modal.style.bottom = "0%";
      }, 100);
    },
    login() {
      let data = {
        email: this.email,
        password: this.password,
      };
      this.global_loading.show = true;
      this.$http
        .post(this.$hostname + "/login", data)
        .then((resp) => {
          this.successRequest();

          this.$store.dispatch("login", {
            auth: true,
          });

          localStorage.auth = true;

          localStorage.token = resp.data.token;

          this.token.config.headers.Authorization =
            "Bearer " + localStorage.token;

          this.closeModal("login_modal");
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    showPassword(password_id) {
      this.eye = this.toggleShowPassword(password_id);
    },
    resetPassword() {
      let login_modal = document.getElementById("login_modal");

      setTimeout(() => {
        login_modal.style.bottom = "-100%";
      }, 100);

      setTimeout(() => {
        login_modal.style.display = "none";
      }, 300);

      let forgot_password_modal = document.getElementById(
        "forgot_password_modal"
      );

      const blur_background = document.getElementById("blur_background");
      blur_background.style.display = "none";

      forgot_password_modal.style.display = "flex";

      blur_background.style.display = "block";

      blur_background.addEventListener(
        "click",
        (event) => {
          if (event.target.id == "blur_background") {
            this.$router.replace({ query: null }).catch(() => {});

            setTimeout(() => {
              forgot_password_modal.style.bottom = "-100%";
            }, 100);
            setTimeout(() => {
              forgot_password_modal.style.display = "none";

              blur_background.style.display = "none";
            }, 300);
            return;
          }
        },
        false
      );

      setTimeout(() => {
        forgot_password_modal.style.bottom = "0%";
      }, 100);
    },
  },
  data() {
    return {
      eye: true,
      email: "",
      password: "",
    };
  },
};
</script>
<style scoped>
.modal {
  min-height: 70vh;
  background-color: #ffffff;
  height: fit-content;
  max-height: 70vh;
  gap: 16px;
  justify-content: flex-start;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  max-width: 768px;
  margin: 0 auto;
  padding: 0 12px 88px;
}

.content {
  background-color: #ffffff;
  height: 100%;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  margin: 0;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #4d4d4d;
  width: 100%;
  padding: 12px 0 0;
}

.close {
  background-image: url("../../assets/common/close.svg");
  height: 28px;
  width: 28px;
}

button {
  width: 100%;
}

.outline {
  position: relative;
}

.google {
  left: 4px;
  bottom: 4px;
  width: 40px;
  height: 40px;
  background-size: 40px;
  position: absolute;
  background-image: url("../../assets/common/google.svg");
}

.note {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #b3b3b3;
}

.forgot {
  font-size: 14px;
  width: 100%;
  text-align: right;
  text-decoration-line: underline;
  color: #b3b3b3;
}
.input label {
  color: #b3b3b3;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #4d4d4d !important;
  -webkit-background-clip: text;
}

.show_password {
  background-image: url("../../assets/common/show_dark.svg");
}
.hide_password {
  background-image: url("../../assets/common/hide_dark.svg");
}

.switch {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 100%;
  color: #ff6a4d;
}
</style>