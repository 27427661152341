<template>
  <div id="nut_filter_modal" class="modal">
    <div class="question">
      <h2>{{ "Calorie filter" }}</h2>
      <div class="desc">
        Please enter the minimum and maximum calorie to filter
      </div>
      <div class="range">
        <div class="value">
          <label class="deactive" for="minimum">Min :</label>
          <input
            class="active"
            type="number"
            min="0"
            inputmode="numeric"
            pattern="[0-9]*"
            name="minimum"
            id="minimum"
            v-model="minimum"
          />
          <span>Kcal</span>
        </div>
        <div class="dash">-</div>
        <div class="value">
          <label class="deactive" for="maximum">Max :</label>
          <input
            class="active"
            type="number"
            min="0"
            inputmode="numeric"
            pattern="[0-9]*"
            name="maximum"
            id="maximum"
            v-model="maximum"
          />
          <span>Kcal</span>
        </div>
      </div>
    </div>
    <button @click="done" class="fill">{{ "Apply filter" }}</button>
  </div>
</template>
<script>
export default {
  methods: {
    done() {
      this.$emit("maximum", this.maximum);
      this.$emit("minimum", this.minimum);

      this.closeModal("nut_filter_modal");
    },
  },
  data() {
    return {
      minimum: "",
      maximum: "",
    };
  },
};
</script>
<style scoped>
.modal {
  height: 270px;
  gap: 12px;
  justify-content: flex-start;
}
.question {
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
h2 {
  font-size: 18px;
  text-align: center;
  color: #737373;
  padding: 16px 0 8px;
  font-weight: normal;
}
.desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #b3b3b3;
}
.range {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  border-top: 1px solid #f5f6f7;
}
.value {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 47%;
  position: relative;
}
input[type="number"] {
  background-color: #f0edf2;
  border-radius: 8px;
  height: 32px;
  width: 70%;
  text-align: left;
  padding: 8px 42px 8px 8px;

}
label {
  width: 30%;
  font-weight: 400;
  font-size: 16px;
  color: #b3b3b3;
}
.value span {
  position: absolute;
  right: 12px;
  font-size: 14px;
  color: #b3b3b3;
}
.dash {
  width: 6%;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #b3b3b3;
}
.fill {
  border-radius: 16px;
}
</style>