<template>
  <div id="reset_password_modal" class="modal">
    <div class="form">
      <h1>
        Reset <br />
        Password
      </h1>
      <h4>Please choose your new password:</h4>

      <div class="input">
        <label class="active" for="reset_password">Password</label>
        <input
          v-model="password"
          class="active"
          type="password"
          name="reset_password"
          id="reset_password"
          @change="passwordValidation"
        />
        <div
          @click="showPassword('reset_password')"
          :class="[eye ? 'show_password' : 'hide_password', 'icon']"
        ></div>
      </div>
      <div v-if="password_invalid" class="validation-error">
        *{{ $t("registration.password_error") }}.
      </div>
      <button @click="submit()" class="fill">Reset Password</button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    showPassword(password_id) {
      this.eye = this.toggleShowPassword(password_id);
    },
    passwordValidation() {
      // this.checkWeaknessPassword();
      if (this.validPassword) {
        this.password_invalid = false;
      } else {
        this.password_invalid = true;
      }
    },
    submit() {
      this.passwordValidation();

      if (this.$route.query.token && this.$route.query.email) {
        this.global_loading.show = true;

        let data = {
          token: this.$route.query.token,
          email: this.$route.query.email,
          password: this.password,
        };

        this.$http
          .post(this.$hostname + "/password/reset", data)
          .then((resp) => {
            this.closeModal("reset_password_modal");
            this.successRequest();
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
  },
  computed: {
    validPassword() {
      return this.password.length >= 8;
    },
  },
  data() {
    return {
      eye: true,
      password: "",
    };
  },
};
</script>
<style scoped>
.modal {
  height: 294px;
  gap: 12px;
  justify-content: flex-start;
}

.form {
  background-color: #ffffff;
  padding: 12px;
  border-radius: 16px;
}
h1 {
  font-weight: 500;
  font-size: 32px;
  color: #737373;
}
.validation-error {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ff6a4d;
  width: 100%;
}
</style>
