<template>
  <div id="otp_verification_modal" class="modal">
    <div class="form">
      <div class="head">
        <h1>
          SMS <br />
          {{ $t("registration.verification") }}
        </h1>
        <button
          @click="closeModal('otp_verification_modal')"
          class="icon close"
        ></button>
      </div>
      <h4>
        {{ $t("registration.enter_code") }}
        {{
          user_signup.phone_number.substring(0, 3) +
          "****" +
          user_signup.phone_number[user_signup.phone_number.length - 2] +
          user_signup.phone_number[user_signup.phone_number.length - 1]
        }}. :
      </h4>
      <div class="digits">
        <input
          v-model="digit_1"
          class="active"
          type="number"
          maxlength="1"
          min="0"
          inputmode="numeric"
          pattern="[0-9]*"
          id="digi_1"
          onKeyPress="if(this.value.length==1) return false;"
          @input="$event.target.nextElementSibling.focus()"
        />

        <input
          v-model="digit_2"
          class="active"
          type="number"
          maxlength="1"
          min="0"
          inputmode="numeric"
          pattern="[0-9]*"
          id="digi_2"
          onKeyPress="if(this.value.length==1) return false;"
          @input="$event.target.nextElementSibling.focus()"
        />

        <input
          v-model="digit_3"
          class="active"
          type="number"
          maxlength="1"
          min="0"
          inputmode="numeric"
          pattern="[0-9]*"
          id="digi_3"
          onKeyPress="if(this.value.length==1) return false;"
          @input="$event.target.nextElementSibling.focus()"
        />

        <input
          v-model="digit_4"
          class="active"
          type="number"
          maxlength="1"
          min="0"
          inputmode="numeric"
          pattern="[0-9]*"
          id="digi_4"
          onKeyPress="if(this.value.length==1) return false;"
        />
      </div>

      <counter
        v-if="!time_over"
        @time_over="timeOver"
        :end="user_signup.expired_at"
      ></counter>
      <div v-else @click="resend()" class="resend">Resend</div>
      <button @click="done()" class="fill">{{ $t("order.submit") }}</button>
    </div>
  </div>
</template>
<script>
import Counter from "../common/Counter.vue";
export default {
  components: { Counter },
  data() {
    return {
      time_over: false,
      digit_1: "",
      digit_2: "",
      digit_3: "",
      digit_4: "",
    };
  },
  props: {
    user: {
      name: "",
      email: "",
      phone_number: "",
      password: "",
    },
  },
  methods: {
    timeOver(val) {
      this.time_over = val;
    },
    resend() {
      let data = {
        phone_number: this.user_signup.phone_number,
        email: this.user_signup.email,
        password: this.user_signup.password,
      };

      this.global_loading.show = true;
      this.$http
        .post(this.$hostname + "/send-otp", data)
        .then((resp) => {
          this.user_signup.expired_at = resp.data.expired_at;
          this.time_over = false;
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    done() {
      let data = {
        name: this.user_signup.name,
        email: this.user_signup.email,
        phone_number: this.user_signup.phone_number,
        password: this.user_signup.password,
        pin: this.digit_1 + this.digit_2 + this.digit_3 + this.digit_4,
      };

      this.global_loading.show = true;

      this.$http
        .post(this.$hostname + "/verify-otp", data)
        .then((resp) => {
          this.successRequest();

          this.$store.dispatch("login", {
            auth: true,
          });

          localStorage.auth = true;

          localStorage.token = resp.data.token;

          this.token.config.headers.Authorization =
            "Bearer " + localStorage.token;

          if (this.$route.name == "Signup") {
            if (localStorage.redirect_to) {
              this.$router.push(localStorage.redirect_to);
            } else {
              this.gotoHome();
            }
          } else {
            this.closeModal("otp_verification_modal");
          }
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  updated() {
    if (this.digit_1[0]) {
      if (this.digit_1[1]) {
        this.digit_2 = this.digit_1[1];
      }
      if (this.digit_1[2]) {
        this.digit_3 = this.digit_1[2];
      }
      if (this.digit_1[3]) {
        this.digit_4 = this.digit_1[3];
      }
      this.digit_1 = this.digit_1[0];
    }
    
    if (this.digit_2[0]) {
      this.digit_2 = this.digit_2[0];
    }
    if (this.digit_3[0]) {
      this.digit_3 = this.digit_3[0];
    }
    if (this.digit_4[0]) {
      this.digit_4 = this.digit_4[0];
    }
  },
};
</script>
<style scoped>
.modal {
  height: 380px;
  gap: 12px;
  justify-content: flex-start;
}

.form {
  background-color: #ffffff;
  padding: 12px;
  border-radius: 16px;
}

h1 {
  font-weight: 500;
  font-size: 32px;
  color: #737373;
}

h4 {
  font-weight: 400;
  color: #737373;
}

.digits {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.input {
  width: 48px;
  height: 48px;
}

input {
  text-align: center;
  width: 48px;
  height: 48px;
  background: #f8f9fa;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

input:focus {
  border: 1px solid #ff6a4c;
  border-radius: 8px;
}
.resend {
  font-size: 14px;
  width: 100%;
  color: #737373;
  text-align: center;
  text-decoration: underline;
}
.head {
  display: flex;
  justify-content: space-between;
}
.close {
  background-image: url("../../assets/common/close.svg");
  height: 28px;
  width: 28px;
}
</style>