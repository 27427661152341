<template>
  <div class="history">
    <div class="header">
      <div class="container">
        <div class="title">{{ $t("history.history") }}</div>
      </div>
    </div>
    <div class="content">
      <switcher
        :first_option="$t('history.order_in_progress')"
        :second_option="$t('history.order_history')"
        @selected_option="SelectedOption"
      ></switcher>
      <div class="orders">
        <order-card
          v-for="(order, index) in customer_orders.data"
          :key="index"
          :order="order"
        ></order-card>
        <empty-history v-if="customer_orders.data.length == 0"></empty-history>
      </div>
    </div>
  </div>
</template>
<script>
import OrderCard from "../components/customer/OrderCard.vue";
import Switcher from "../components/customer/Switcher.vue";
import EmptyHistory from "../components/customer/EmptyHistory.vue";
export default {
  components: { Switcher, OrderCard, EmptyHistory },
  mounted() {
    this.getNewUpdate();

    this.fetchOrdersInProgress();
  },
  methods: {
    SelectedOption(val) {
      this.type = val; // true=in progress, false=history
      if (this.type) {
        this.fetchOrdersInProgress();
      } else {
        this.fetchOrdersHistory();
      }
    },
  },
  data() {
    return {
      orders: [],
    };
  },
};
</script>
<style scoped>
.header {
  z-index: 7;
}
.content {
  gap: 16px;
  overflow-y: scroll;
  height: 88vh;
  padding-top: 70px;
}
.orders {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
</style>