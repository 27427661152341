<template>
  <router-link :to="'/menu/' + restaurant.menu">
    <div class="btn restaurant">
      <div class="icon advertise"></div>

      <div
        class="photo"
        :style="[
          restaurant.photo
            ? {
                'background-image': 'url(' + restaurant.photo + ')',
              }
            : '',
        ]"
      ></div>
      <div class="detail">
        <div class="head">
          <h3>
            {{ restaurant.restaurant }}
          </h3>
          <div :class="[restaurant.status == 1 ? 'open' : 'closed', 'status']">
            {{ restaurant.status == 1 ? $t("menu.open") : $t("menu.closed") }}
          </div>
        </div>

        <div class="type">{{ restaurant.type }}</div>
        <div class="footer">
          <div class="rate">
            <div class="icon star"></div>
            <span>
              {{ restaurant.average + " / 5" }}
            </span>
          </div>
          <div class="delivery">
            <div class="icon bike"></div>
            <span>
              {{ formatPrice(restaurant.delivery_charge) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    restaurant: {},
  },
};
</script>
<style scoped>
.restaurant {
  justify-content: flex-start;
  gap: 12px;
  padding: 12px;
  position: relative;
}
h3 {
  color: #4d4d4d;
  width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.detail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 64%;
  width: 70%;
}
.head {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.type {
  color: #b3b3b3;
  font-size: 14px;
  width: 100%;
}
.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.delivery {
  display: flex;
  align-items: center;
  gap: 2px;
}
.delivery span {
  font-weight: 400;
  font-size: 12px;
  color: #b3b3b3;
}
.photo {
  min-width: 86px;
  width: 30%;
  height: 82px;
  border-radius: 8px;
  background-image: url("../../assets/customer/restaurant/restaurant.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.advertise {
  position: absolute;
  top: -26%;
  left: -27px;
  z-index: 996;
  width: 110px;
  height: 110px;
  background-image: url("../../assets/customer/restaurant/advertise.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>