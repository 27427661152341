var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"options"},[(_vm.wifi == 1)?_c('div',{staticClass:"option"},[_c('div',{staticClass:"icon wifi"}),_c('div',{staticClass:"caption"},[_vm._v("Free WI-FI")])]):_vm._e(),(_vm.wifi == 1 && _vm.bar == 1)?_c('div',{staticClass:"line"}):_vm._e(),(_vm.bar == 1)?_c('div',{staticClass:"option"},[_c('div',{staticClass:"bar icon"}),_c('div',{staticClass:"caption"},[_vm._v("Bar")])]):_vm._e(),((_vm.parking == 1 && _vm.wifi == 1) || (_vm.parking == 1 && _vm.bar == 1))?_c('div',{staticClass:"line"}):_vm._e(),(_vm.parking == 1)?_c('div',{staticClass:"option"},[_c('div',{staticClass:"parking icon"}),_c('div',{staticClass:"caption"},[_vm._v("Car Park")])]):_vm._e(),(
      (_vm.wheelchair == 1 && _vm.wifi == 1) ||
      (_vm.wheelchair == 1 && _vm.bar == 1) ||
      (_vm.wheelchair == 1 && _vm.parking == 1)
    )?_c('div',{staticClass:"line"}):_vm._e(),(_vm.wheelchair == 1)?_c('div',{staticClass:"option"},[_c('div',{staticClass:"icon wheelchair"}),_c('div',{staticClass:"caption"},[_vm._v("Wheelchair Accessible")])]):_vm._e(),(
      (_vm.outdoor == 1 && _vm.wifi == 1) ||
      (_vm.outdoor == 1 && _vm.bar == 1) ||
      (_vm.outdoor == 1 && _vm.parking == 1) ||
      (_vm.outdoor == 1 && _vm.wheelchair == 1)
    )?_c('div',{staticClass:"line"}):_vm._e(),(_vm.outdoor == 1)?_c('div',{staticClass:"option"},[_c('div',{staticClass:"outdoor icon"}),_c('div',{staticClass:"caption"},[_vm._v("Outdoor Dining")])]):_vm._e(),(
      (_vm.smoking == 1 && _vm.wifi == 1) ||
      (_vm.smoking == 1 && _vm.bar == 1) ||
      (_vm.smoking == 1 && _vm.parking == 1) ||
      (_vm.smoking == 1 && _vm.wheelchair == 1) ||
      (_vm.smoking == 1 && _vm.outdoor == 1)
    )?_c('div',{staticClass:"line"}):_vm._e(),(_vm.smoking == 1)?_c('div',{staticClass:"option"},[_c('div',{staticClass:"icon smoking"}),_c('div',{staticClass:"caption"},[_vm._v("No Smoking")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }