<template>
  <div class="btn rating">
    <input
      type="radio"
      class="green"
      name="rate"
      id="star-5"
      @click="vote(5)"
    />
    <input
      type="radio"
      class="green"
      name="rate"
      id="star-4"
      @click="vote(4)"
    />
    <input
      type="radio"
      class="orange"
      name="rate"
      id="star-3"
      @click="vote(3)"
    />
    <input type="radio" class="red" name="rate" id="star-2" @click="vote(2)" />
    <input type="radio" class="red" name="rate" id="star-1" @click="vote(1)" />
  </div>
</template>

<script>
export default {
  name: "StarRating",
  methods: {
    vote(rate) {
      localStorage.setItem("vote", rate);
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 100%;
  padding: 12px 0 12px;
}

.rating input {
  appearance: none;
  background-image: url("../../assets/common/empty.svg");
  background-repeat: no-repeat;
  background-size: 48px;
  margin: 0;
  width: 48px;
  height: 48px;
  cursor: pointer;
  transition: 0.4s ease;
  outline: none;
}

.red:hover,
.red:hover ~ input,
.red:checked,
.red:checked ~ input {
  background-image: url("../../assets/common/bad.svg");
  transition: 0.4s ease;
}
.orange:hover,
.orange:hover ~ input,
.orange:checked,
.orange:checked ~ input {
  background-image: url("../../assets/common/normal.svg");
  transition: 0.4s ease;
}
.green:hover,
.green:hover ~ input,
.green:checked,
.green:checked ~ input {
  background-image: url("../../assets/common/good.svg");
  transition: 0.4s ease;
}
</style>
