<template>
  <div class="pointer" @click="changeLanguage($i18n.locale == 'fi' ? 'en' : 'fi')">
    <button class="language"></button>
    <div :class="[$i18n.locale == 'fi' ? 'finnish' : 'english', 'icon']"></div>
  </div>
</template>
<script>
export default {
  methods: {
    changeLanguage(val) {
      this.$i18n.locale = val;
      localStorage.language = val;
    },
  },
};
</script>
<style scoped>
.pointer{
  cursor: pointer;
}

.language {
  width: 40px;
  height: 40px;
  background-size: 40px;
  background: #edefff;
  opacity: 0.2;
  border-radius: 8px;
  position: relative;
}

.icon {
  width: 40px;
  height: 40px;
  background-size: 30px;
  background-position: center;
  opacity: 1;
  background-repeat: no-repeat;
  position: absolute;
  top: 16px;
}

.finnish {
  background-image: url("../../assets/common/fi.png");
}
.english {
  background-image: url("../../assets/common/en.png");
}
</style>