<template>
  <div id="install_guide_modal" class="modal">
    <div class="question">
      <div class="banner"></div>
      <div class="description">
        {{ $t("customer_home.safari_guide") }}
      </div>
      <div class="steps">
        <div class="share">
          <div class="icon"></div>
          <div class="description">{{ $t("customer_home.share_icon") }}</div>
        </div>
        <div class="icon next"></div>
        <div class="add_home">
          <div class="icon"></div>
          <div class="description">
            {{ $t("customer_home.home_screen") }}
          </div>
        </div>
      </div>
    </div>
    <button @click="done" class="outline">
      {{ $t("customer_home.understand") }}
    </button>
  </div>
</template>
<script>
export default {
  methods: {
    done() {
      this.closeModal("install_guide_modal");
    },
  },
};
</script>
<style scoped>
.modal {
  gap: 12px;
  justify-content: flex-start;
}
.question {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 16px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.banner {
  width: 148px;
  height: 44px;
}
.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  width: 100%;
  color: #737373;
}
.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.steps .description {
  font-size: 12px;
}

.share,
.add_home {
  width: 40%;
}

.next {
  width: 24px;
  background-image: url("../../assets/customer/restaurant/next.svg");
}

.add_home .icon {
  height: 48px;
  background-size: 36px;
  background-image: url("../../assets/customer/restaurant/add_home.svg");
}

.share .icon {
  height: 44px;
  background-image: url("../../assets/customer/restaurant/share.svg");
}
</style>