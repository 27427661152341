<template>
  <div class="change-password">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/profile'"></back-button>
        <div class="title">{{ $t("profile.change_password") }}</div>
        <span></span>
      </div>
    </div>
    <div class="content">
      <div class="form">
        <div class="input">
          <label class="active" for="password">{{
            $t("profile.current_password")
          }}</label>
          <input
            v-model="current_password"
            class="active"
            type="password"
            name="password"
            id="password"
          />
          <div
            @click="showPassword('password')"
            :class="[eye ? 'show_password' : 'hide_password', 'icon']"
          ></div>
        </div>
        <div class="input">
          <label class="active" for="new_password">{{
            $t("profile.new_password")
          }}</label>
          <input
            v-model="new_password"
            class="active"
            type="password"
            name="new_password"
            id="new_password"
          />
          <div
            @click="showNewPassword('new_password')"
            :class="[new_eye ? 'show_password' : 'hide_password', 'icon']"
          ></div>
        </div>
      </div>
      <button @click="changePassword" class="fill reset">
        {{ $t("profile.change_password") }}
      </button>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";

export default {
  components: { BackButton },
  mounted() {
    this.iOSBottomGapFixer(".content");
  },
  methods: {
    showPassword(password_id) {
      this.eye = this.toggleShowPassword(password_id);
    },
    showNewPassword(password_id) {
      this.new_eye = this.toggleShowPassword(password_id);
    },
    changePassword() {
      this.global_loading.show = true;
      let data = {
        current_password: this.current_password,
        password: this.new_password,
      };
      this.$http
        .patch(
          this.$hostname + "/client/change-password",
          data,
          this.token.config
        )
        .then((resp) => {
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      eye: true,
      new_eye: true,
      current_password: "",
      new_password: "",
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}

.content {
  height: 100vh;
  padding: 70px 16px;
  justify-content: space-between;
}

input {
  width: 49%;
}

.input {
  position: relative;
}
.show_password {
  background-image: url("../../assets/common/show_dark.svg");
}
.hide_password {
  background-image: url("../../assets/common/hide_dark.svg");
}
</style>