<template>
  <div class="modal" id="succeed_modal">
    <div class="content">
      <div class="icon succeed"></div>
      <div class="message">{{ message }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    message: "",
  },
};
</script>
<style scoped>
.modal {
  padding: 12px 12px 80%;
}
@media screen and (min-width: 766px) {
  .modal {
    padding: 12px 12px 400px;
  }
}
.content {
  background-color: #ffffff;
  height: 180px;
  border-radius: 16px;
  padding: 0;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.succeed {
  height: 80px;
  width: 80px;
  background-size: 80px;
  background-image: url("../../assets/common/succeed.svg");
}

.message {
  text-align: center;
}
</style>