<template>
  <div class="modal" id="payment_terms_modal">
    <div class="content">
      <div class="head">
        <button
          @click="closeModal('payment_terms_modal')"
          class="icon close"
        ></button>
        <div>Maksutavat ja toimitusehdot</div>
        <span></span>
      </div>
      <div class="body">
        <img src="../../assets/customer/visma/column.png" class="mobile" />
        <img src="../../assets/customer/visma/row.png" class="desktop" />
        <h3>Toimitusehdot</h3>
        <p>
          NORDANTIA OY 3018645-9 (jäljempänä sovellus) välittää myyntejä
          yksityishenkilöille Suomeen. Pidätämme oikeuden toimitusehtojen ja
          hintojen muutoksiin. Tuotteiden hinnat sisältävät arvonlisäveron.
        </p>
        <h3>Sovelluksen yhteystiedot</h3>
        <div class="contact-info">
          <b> Sähköposti: service@nordantia.com </b>
          <b> Puhelin: 041 314 7604 </b>
          <b> Postiosoite: Kyläojankatu 13 A 2, 33700 Tampere </b>
        </div>

        <h3>Maksaminen</h3>
        <p>
          Sovelluksen maksuvälittäjänä toimii Visma Pay (Visma Payments Oy,
          y-tunnus 2486559-4), joka on rekisteröity Finanssivalvonnan
          ylläpitämään maksulaitosrekisteriin. Maksamiseen siirrytään Visma Payn
          verkkopalvelun kautta ja tiliotteella ja laskulla maksun saajana näkyy
          Visma Pay tai Visma Payments Oy. Visma Pay välittää maksut
          sovelluskauppiaalle. Maksaminen on turvallista, sillä kaikki
          maksutapahtumaa koskevat tiedot välitetään salattua yhteyttä käyttäen
          niin ettei kukaan ulkopuolinen taho näe maksutapahtuman tietoja.
        </p>
        <p>
          Kauppa syntyy sovelluksen asiakkaan ja tuotteen toimittajan välille.
          Tuotteen toimittajan vastuulla ovat kaikki kauppaan liittyvät
          velvoitteet.
        </p>

        <span>
          Lue lisää Visma Paysta:<a href="https://www.visma.fi/vismapay/">
            https://www.visma.fi/vismapay/
          </a>
        </span>

        <h3>Maksutavat</h3>

        <p>
          Visma Pay -palvelun kautta voit maksaa verkkopankkitunnuksilla,
          lompakolla, maksukorteilla (credit/debit), laskulla tai osamaksulla.
          Käytettävissä ovat seuraavat maksutavat: Osuuspankki, Nordea, Danske
          Bank, Oma Säästöpankki, Säästöpankki, Aktia, Paikallisosuuspankit,
          S-Pankki, Handelsbanken, Ålandsbanken, MobilePay, Masterpass, Pivo,
          Visa-, Visa Debit-, Visa Electron-, MasterCard- ja Debit MasterCard
          -kortit, sekä Jousto, Fellow Lasku ja Fellow Yrityslasku.
        </p>

        <p>
          MobilePay: Voit maksaa MobilePay-lompakollasi mikäli olet sallinut
          verkkokaupoissa maksamisen sovelluksen asetuksista.
          MobilePay-lompakolla suoritetut maksut veloitetaan lompakkoon
          liitetyltä maksukortilta. Mikäli maksun veloittaminen maksukortilta
          epäonnistuu, MobilePay-lompakolla maksaminen ei ole mahdollista
          sovelluksessa.
        </p>

        <p>
          Pivo: Käyttöehdot ovat tarjolla Pivon sivuilla:
          <a href="https://pivo.fi/kayttoehdot/pivon-kayttoehdot/">
            https://pivo.fi/kayttoehdot/pivon-kayttoehdot/</a
          >
        </p>
        <p>
          Jousto lasku ja osamaksu on kotimainen palvelu, jolla teet ostoksesi
          nopeasti ja turvallisesti. Jousto on tarkoitettu yksityishenkilöille,
          jotka ovat hoitaneet raha-asiansa moitteettomasti. Joustolla saat 30
          vuorokautta korotonta ja kulutonta maksuaikaa. Laskun saatuasi voit
          päättää maksatko sen kokonaan vai osissa. Osamaksulla voit maksaa
          ostoksesi jopa 36:ssa erässä, alkaen 9,90 eur/kk. Jousto osamaksun
          kustannukset ovat 3,90 eur/kk ja 19,90%:n luottokorko. Voit maksaa
          Joustolla 303000 euron ostoksia. Luotonmyöntäjänä toimii Aurajoki
          Nordic Oy. Lue lisää Joustosta osoitteessa www.jousto.com.
        </p>
        <p>
          OP Lasku - Joustava tapa maksaa verkko-ostokset. Kaikkien pankkien
          asiakkaille. OP Laskulla voit tehdä enintään 5 000 euron suuruisia
          ostoksia ja maksaa ne korottomasti pois 45 vuorokauden sisällä.
          Halutessasi voit pilkkoa laskun useampaan maksuerään. Ostoksesi
          näkyvät yhdessä paikassa, joten pysyt helposti perillä rahan käytöstä.
          Luottorajan avulla pidät kulutuksen haluamissasi rajoissa. Saat laskut
          kätevästi sähköpostiisi. Verkkopalvelussamme näet tietoja OP Laskun
          käytöstäsi ja tekemiesi ostosten summan. Voit käyttää OP Laskua, jos
          olet maksukykyinen yli 20-vuotias, ja sinulla on suomalaisen pankin
          verkkotunnukset. Lisätietoja OP Laskuun liittyen löydät osoitteesta:
          <a
            href="https://www.op.fi/henkiloasiakkaat/paivittaiset/maksaminen/op-lasku"
          >
            https://www.op.fi/
          </a>
        </p>
        <h3>Visma Pay -maksupalvelun yhteystiedot</h3>
        <div class="contact-info">
          <b> Visma Payments Oy (Y-tunnus 2486559-4) </b>
          <b> Sähköposti: helpdesk@vismapay.com </b>
          <b> Puhelin: 09 315 42 037 (arkisin klo 8-16) </b>
          <b> Postiosoite: Brahenkatu 4, 53100 Lappeenranta </b>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.modal {
  padding: 0;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #4d4d4d;
  width: 100%;
  border-bottom: 1px solid #f0edf2;
  padding: 12px 0;
}

.content {
  background-color: #ffffff;
  height: 100vh;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  height: 75vh;
  width: 100%;
  overflow-y: scroll;
}
b,
h3 {
  text-align: left;
  width: 100%;
}


.desktop {
  display: none;
  width: 700px;
}
.contact-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media screen and (min-width: 811px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
}
.close {
  background-image: url("../../assets/common/close.svg");
  height: 28px;
  width: 28px;
}
</style>