<template>
  <div
    class="banner"
    :style="[
      photo
        ? {
            'background-image': 'url(' + photo.url + ')',
          }
        : '',
      status == 1
        ? {
            opacity: '1',
          }
        : {
            opacity: '0.5',
          },
    ]"
  >
    <div class="footer restaurant">
      <!-- <div :class="[status == 1 ? 'open' : 'closed', 'status']">
        {{ status == 1 ? $t("menu.open") : $t("menu.closed") }}
      </div> -->
      <div
        v-if="restaurant.delivery == 1 && restaurant.free_delivery_option == 1"
        class="free-delivery"
      >
        <div class="delivery-fee">
          <span>Free</span>
          <div class="icon bike-white"></div>
          :
        </div>
        <span>
          {{
            $t("menu.free_delivery_for.part_1") +
            formatPrice(restaurant.minimum_order_free_delivery)
          }}
        </span>
        <span v-if="restaurant.first_free_delivery_option == 1">
          {{
            $t("menu.free_delivery_for.part_1") +
            formatPrice(restaurant.first_min_amount_free_delivery) +
            $t("menu.free_delivery_for.part_2") +
            restaurant.first_max_distance_free_delivery +
            " km"
          }}
        </span>
      </div>
      <div v-if="restaurant.delivery == 1" class="more-detail">
        <div class="min-amount">
          {{
            formatPrice(restaurant.min_amount_order_delivery) +
            " " +
            $t("customer_home.minimum")
          }}
        </div>
        <span class="seprater">•</span>

        <div class="time-estimate">
          {{
            restaurant.max_preparation_time
              ? restaurant.max_preparation_time
              : 0
          }}
          min
        </div>
        <span class="seprater">•</span>

        <div v-if="restaurant.delivery_fees.length > 0" class="delivery-fee">
          <div class="icon bike"></div>
          {{ deliveryFeeFormatter(restaurant) }}
        </div>
        <div v-else class="note">
          {{ $t("information.free_delivery") }}
        </div>
      </div>
    </div>
    <!-- <div class="rate">
      <div class="icon star"></div>
      <span>
        {{ rate + " / 5" }}
      </span>
      <span class="review">({{ reviews }} reviews)</span>
    </div> -->
  </div>
</template>
<script>
export default {
  props: {
    status: 0,
    rate: 0,
    reviews: 0,
    photo: {},
    restaurant: {},
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  min-height: 240px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/customer/restaurant/restaurant.png");
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: flex-end;
}
.footer {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  max-width: 1540px;
  margin: 0 auto;
}

.free-delivery,
.more-detail {
  background-color: #ffffff;
  box-shadow: -2px -2px 8px rgb(0 0 0 / 8%);
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 12px;
}

.free-delivery {
  color: #ffffff;
  background-color: #ff6a4d;
  display: flex;
  flex-direction: column;
}

.more-detail {
  color: #4d4d4d;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.seprater {
  color: #b3b3b3;
  font-weight: 900;
}

.rate {
  background-color: #f8f9fa;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 0 8px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.review {
  font-size: 10px;
}

.note {
  color: #ff6a4d;
}
.delivery-fee {
  display: flex;
  gap: 4px;
}
.bike {
  background-image: url("../../assets/customer/menu/bike.svg");
  background-color: transparent;
  width: 15px;
  height: 15px;
  background-size: 15px;
}

.bike-white {
  background-image: url("../../assets/customer/menu/bike_white.svg");
  background-color: transparent;
  width: 15px;
  height: 15px;
  background-size: 15px;
}
</style>
