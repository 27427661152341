var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner",style:([
    _vm.photo
      ? {
          'background-image': 'url(' + _vm.photo.url + ')',
        }
      : '',
    _vm.status == 1
      ? {
          opacity: '1',
        }
      : {
          opacity: '0.5',
        } ])},[_c('div',{staticClass:"footer restaurant"},[(_vm.restaurant.delivery == 1 && _vm.restaurant.free_delivery_option == 1)?_c('div',{staticClass:"free-delivery"},[_vm._m(0),_c('span',[_vm._v(" "+_vm._s(_vm.$t("menu.free_delivery_for.part_1") + _vm.formatPrice(_vm.restaurant.minimum_order_free_delivery))+" ")]),(_vm.restaurant.first_free_delivery_option == 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("menu.free_delivery_for.part_1") + _vm.formatPrice(_vm.restaurant.first_min_amount_free_delivery) + _vm.$t("menu.free_delivery_for.part_2") + _vm.restaurant.first_max_distance_free_delivery + " km")+" ")]):_vm._e()]):_vm._e(),(_vm.restaurant.delivery == 1)?_c('div',{staticClass:"more-detail"},[_c('div',{staticClass:"min-amount"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.restaurant.min_amount_order_delivery) + " " + _vm.$t("customer_home.minimum"))+" ")]),_c('span',{staticClass:"seprater"},[_vm._v("•")]),_c('div',{staticClass:"time-estimate"},[_vm._v(" "+_vm._s(_vm.restaurant.max_preparation_time ? _vm.restaurant.max_preparation_time : 0)+" min ")]),_c('span',{staticClass:"seprater"},[_vm._v("•")]),(_vm.restaurant.delivery_fees.length > 0)?_c('div',{staticClass:"delivery-fee"},[_c('div',{staticClass:"icon bike"}),_vm._v(" "+_vm._s(_vm.deliveryFeeFormatter(_vm.restaurant))+" ")]):_c('div',{staticClass:"note"},[_vm._v(" "+_vm._s(_vm.$t("information.free_delivery"))+" ")])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"delivery-fee"},[_c('span',[_vm._v("Free")]),_c('div',{staticClass:"icon bike-white"}),_vm._v(" : ")])}]

export { render, staticRenderFns }