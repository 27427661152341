<template>
  <div @click="rate" class="rate">
    <button class="fill">{{ $t("rate.rate_order") }}</button>
  </div>
</template>

<script>
export default {
  name: "Continue",
  props: {
    order: {},
  },
  methods: {
    rate() {
      this.order_processing.data = this.order;
      this.showModal("rate_modal");
    },
  },
};
</script>
<style scoped>
.rate {
  display: flex;
  align-items: flex-end;
  position: fixed;
  width: 100%;
  bottom: 9vh;
  z-index: 980;
  transition: 0.2s ease;
  padding: 0 16px;
}
.fill {
  width: 100%;
  max-width: 768px;
  margin: auto;
}
</style>
