<template>
  <div id="forgot_password_modal" class="modal">
    <div class="form">
      <h1>
        Forgot <br />
        Password
      </h1>
      <h4>Enter your email below to get the reset link</h4>

      <div class="input">
        <label class="active" for="email">{{ $t("information.email") }}</label>
        <input v-model="email" class="active" type="email" id="email" />
      </div>

      <button @click="done()" class="fill">Send Email</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    done() {
      this.global_loading.show = true;

      let data = {
        email: this.email,
      };

      this.$http
        .post(this.$hostname + "/password/create-token", data)
        .then((resp) => {
          this.successRequest();

          this.closeModal("forgot_password_modal");

          setTimeout(() => {
            this.global_success.message = "Please check your inbox.";
            this.showModal("succeed_modal");
          }, 301);

          setTimeout(() => {
            this.closeModal("succeed_modal");
          }, 3000);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
};
</script>
<style scoped>
.modal {
  height: 380px;
  gap: 12px;
  justify-content: flex-start;
}

.form {
  background-color: #ffffff;
  padding: 12px;
  border-radius: 16px;
}
h1 {
  font-weight: 500;
  font-size: 32px;
  color: #737373;
}
</style>