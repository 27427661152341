<template>
  <div class="contact">
    <div class="location">
      <div class="icon"></div>
      <div class="caption">{{ address }}</div>
    </div>
    <a :href="'tel:' + phone_number">
      <div class="phone">
        <div class="icon"></div>

        <div class="caption">{{ phone_number }}</div>
      </div>
    </a>
    <div class="social-apps">
      <a v-if="facebook" :href="'https://facebook.com/' + facebook">
        <div class="icon facebook"></div>
      </a>
      <a v-if="instagram" :href="'https://instagram.com/' + instagram">
        <div class="icon instagram"></div>
      </a>
      <a v-if="twitter" :href="'https://twitter.com/' + twitter">
        <div class="icon twitter"></div>
      </a>
      <a v-if="tiktok" :href="'https://tiktok.com/' + tiktok">
        <div class="icon tiktok"></div>
      </a>
      <a v-if="google_maps" :href="google_maps">
        <div class="icon google"></div>
      </a>
      <a v-if="website" :href="website">
        <div class="icon website"></div>
      </a>
      <!-- <div class="icon telegram"></div> -->
      <!-- <div class="icon email"></div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    address: "",
    phone_number: "",
    facebook: "",
    instagram: "",
    twitter: "",
    tiktok: "",
    google_maps: "",
    website: "",
  },
};
</script>
<style scoped>
.contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}
.location,
.phone {
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  color: #4d4d4d;
}
.location .caption {
  width: 85%;
}
.social-apps {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 24px;
}
.icon {
  width: 24px;
}
.facebook {
  background-image: url("../../assets/customer/menu/facebook.svg");
}
.instagram {
  background-image: url("../../assets/customer/menu/instagram.svg");
}
.twitter {
  background-image: url("../../assets/customer/menu/twitter.svg");
}
.website {
  background-image: url("../../assets/customer/menu/website.svg");
}
.google {
  background-image: url("../../assets/customer/menu/google.svg");
}
.tiktok {
  background-image: url("../../assets/customer/menu/tiktok.svg");
}
.telegram {
  background-image: url("../../assets/customer/menu/telegram.svg");
}
.email {
  background-image: url("../../assets/customer/menu/email.svg");
}
.phone .icon {
  background-image: url("../../assets/customer/menu/phone.svg");
}
.location .icon {
  background-image: url("../../assets/customer/menu/location.svg");
}
</style>